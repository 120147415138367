import axios, { AxiosInstance } from 'axios';
import { store } from '../redux/store/store';
import { logout } from '../redux/actions/authActions';

const getBaseUrl = () => {
  let url;
  switch(process.env.NODE_ENV) {
    case 'production':
      url = 'https://devis.studioapp.fr/api'
      break;
    case 'test':
      url = 'https://backend.appstudio.myjunas.ovh/api';
      break;
    case 'development':
    default:
      url = 'http://localhost:8090/api';
  }

  return url;
};

// Créez une instance Axios avec l'URL de base déterminée par l'environnement
const axiosInstance: AxiosInstance = axios.create({
  baseURL: getBaseUrl(),
});

axiosInstance.interceptors.request.use(config => {
  const token = store.getState().auth.accessToken;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Ajoutez un intercepteur de réponse pour gérer les erreurs 403
axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 403) {
    // Dispatch une action pour déconnecter l'utilisateur
    store.dispatch(logout());
  }
  return Promise.reject(error);
});


export default axiosInstance;
